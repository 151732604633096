<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <v-card
              outlined
              style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              "
            >
              <v-card-text class="my-0 py-0">
                <v-list dense subheader>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">groups</v-icon>
                    Parties</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item v-if="booking.customer && booking.customer.name">
                    <v-list-item-avatar
                      :color="booking.customer.logo ? 'white' : 'secondary'"
                    >
                      <v-img
                        v-if="booking.customer.logo"
                        :src="booking.customer.logo"
                        contain
                      ></v-img>
                      <!-- </v-avatar> -->
                      <!-- <v-avatar v-else color="secondary"> -->
                      <h2 v-else>
                        {{ booking.customer.name.charAt(0) }}
                      </h2>
                      <!-- </v-avatar> -->
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ booking.customer.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Selected Customer
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row justify="center">
                        <v-btn small @click="customerSearchModal = true" icon
                          ><v-icon small>edit</v-icon></v-btn
                        >
                        <v-btn small @click="removeCustomer()" color="red" icon
                          ><v-icon small>delete</v-icon></v-btn
                        >
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-else @click="customerSearchModal = true">
                    <v-list-item-action>
                      <v-icon color="primary">add_circle_outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <span style="font-size: 16px">Add Customer</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="booking.customer" :key="profileKey">
                    <v-list-item-action>
                      <v-icon color="secondary">handshake</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.consigneeProfile">
                        {{ booking.consigneeProfile.systemReference }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle>
                        Shipment Profile
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row justify="center" v-if="booking.consigneeProfile">
                        <v-btn small @click="shipmentProfileDialog = true" icon
                          ><v-icon small>edit</v-icon></v-btn
                        >
                        
                        <v-btn small @click="removeProfile()" color="red" icon
                          ><v-icon small>delete</v-icon></v-btn
                        >
                      </v-row>
                      <v-btn
                        icon
                        color="primary"
                        v-else
                        @click="shipmentProfileDialog = true"
                      >
                        <v-icon>add_circle_outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item
                    v-if="booking.consigneeProfile"
                    @click="shipmentProfileConfig = true"
                  >
                    <v-list-item-action>
                      <v-icon v-if="booking.dealTerm" color="secondary"
                        >settings</v-icon
                      >
                      <v-icon v-else color="primary">add_circle_outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content v-if="!booking.dealTerm">
                      <span style="font-size: 16px"
                        >Select Profile Inco Term</span
                      >
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-title>
                        {{ booking.dealTerm.incoTerm }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Profile Inco Term
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="incoTermModal = true" v-if="!booking.consigneeProfile">
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  !booking.incoTerm ? 'secondary' : 'grey'
                                "
                                >swap_horiz</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title v-if="booking.incoTerm">
                                {{ booking.incoTerm }}
                              </v-list-item-title>
                              <v-list-item-title v-else>-</v-list-item-title>
                              <v-list-item-subtitle>
                                Inco Term
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                  <div v-if="booking.customer">
                    <v-list-item
                      :disabled="
                        booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                      "
                      v-if="booking.movementType !== 'IMPORT'"
                      @click="(partyModal = true), (partyType = 'Shipper')"
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >directions_boat_filled</v-icon
                        >
                   
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="booking.shipper">
                          {{ booking.shipper.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle v-if="booking.onBehalfShipper">
                          On behalf of
                          {{ booking.onBehalfShipper.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Shipper
                        </v-list-item-subtitle>

                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item 
                    :disabled="
                        booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                      "
                    v-else>
                    <v-list-item-action>
                      <v-icon >directions_boat_filled</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.shipper">
                        {{ booking.shipper.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle v-if="booking.onBehalfShipper">
                        On behalf of
                          {{ booking.onBehalfShipper.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Shipper
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row justify="center" v-if="booking.shipper">
                        <v-btn small      @click="(partyModal = true), (partyType = 'Shipper')" icon
                          ><v-icon small>edit</v-icon></v-btn
                        >
                        
                        <v-btn small @click="booking.shipper = null,removeImportShipperPart()" color="red" icon
                          ><v-icon small>delete</v-icon></v-btn
                        >
                      </v-row>
                      <v-btn
                        icon
                        color="primary"
                        v-else
                        @click="(partyModal = true), (partyType = 'Shipper')"
                      >
                        <v-icon>add_circle_outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    </v-list-item>
                    <v-list-item
                      :disabled="
                        booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                      "
                      @click="(partyModal = true), (partyType = 'Forwarder')"
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >fast_forward</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="booking.forwarder">
                          {{ booking.forwarder.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle v-if="booking.onBehalfForwarder">
                          On behalf of
                          {{ booking.onBehalfForwarder.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Forwarder
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      :disabled="
                        booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                      "
                      @click="(partyModal = true), (partyType = 'Consignee')"
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >call_received</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="booking.consignee">
                          {{ booking.consignee.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle v-if="booking.onBehalfConsignee">
                          On behalf of
                          {{ booking.onBehalfConsignee.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Consignee
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="!booking.consigneeProfile || booking.buyer"
                      :disabled="
                        booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                      "
                      @click="(partyModal = true), (partyType = 'Buyer')"
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >shopping_cart</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="booking.buyer">
                          {{ booking.buyer.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle v-if="booking.onBehalfBuyer">
                          On behalf of {{ booking.onBehalfBuyer.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Buyer
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="!booking.consigneeProfile || booking.firstNotify"
                      :disabled="
                        booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                      "
                      @click="(partyModal = true), (partyType = 'First Notify')"
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >notifications</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="booking.firstNotify">
                          {{ booking.firstNotify.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Notify Party
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="!booking.consigneeProfile || booking.secondNotify"
                      :disabled="
                        booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                      "
                      @click="
                        (partyModal = true), (partyType = 'Second Notify')
                      "
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >notifications</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="booking.secondNotify">
                          {{ booking.secondNotify.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          2nd Notify Party
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="!booking.consigneeProfile || booking.courierParty"
                      :disabled="
                        booking.consigneeProfile != undefined ||
                        booking.consigneeProfile != null
                      "
                      @click="
                        (partyModal = true), (partyType = 'Courier Party')
                      "
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >local_post_office</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="booking.courierParty">
                          {{ booking.courierParty.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Courier Party
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      @click="(partyModal = true), (partyType = 'Stock Provider')"
                    >
                      <v-list-item-action>
                        <v-icon class="mt-0 pt-0" color="grey"
                          >fact_check</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="booking.stockProvider">
                          {{ booking.stockProvider.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-icon x-small color="grey" class="mr-1"
                            >label</v-icon
                          >
                          Stock Provider
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                  </div>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card
              outlined
              style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              "
            >
              <v-card-text class="my-0 py-0">
                <!-- <v-list dense subheader v-if="integrationForms.length > 0">
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">article</v-icon>
                    Forms</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item
                    v-for="form in integrationForms"
                    :key="form.id"
                    @click="viewForm(form)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ form.alias }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list> -->
                <v-list dense subheader>
                  <!-- <v-divider></v-divider> -->

                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">gavel</v-icon>
                    Contracts
                    <v-btn color="primary" @click="addContractItem()" icon
                      ><v-icon>add_circle_outline</v-icon></v-btn
                    ></v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item v-if="booking.linkedContracts.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No Contracts</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="contract in booking.linkedContracts"
                    :key="contract.id"
                    @click="addContractItem(contract)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        contract.contractNo
                      }}</v-list-item-title>
                      <v-list-item-subtitle v-if="contract.contractOwner"
                        ><v-icon small color="grey" class="mr-1">groups</v-icon>
                        {{ contract.contractOwner.name }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        ><v-chip x-small label outlined style="border: none">
                          <v-icon x-small left color="grey">label</v-icon
                          >{{ contract.type }}
                          <v-chip
                            class="ml-1"
                            v-if="contract.bookingContainer"
                            x-small
                            label
                            outlined
                            style="border: none"
                            ><v-icon x-small left color="grey">widgets</v-icon
                            >{{ contract.bookingContainer.ctoNo }}</v-chip
                          >
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list dense subheader v-if="booking.bookingPaymentDetails">
                  <v-divider></v-divider>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">payments</v-icon>
                    Payment Terms
                    <v-btn
                      class="ml-1"
                      icon
                      @click="addPayment()"
                      color="primary"
                      ><v-icon>add_circle_outline</v-icon></v-btn
                    >
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item v-if="booking.bookingPaymentDetails.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No payment details listed</span>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item
                    v-for="payment in booking.bookingPaymentDetails"
                    :key="payment.id"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <b>{{ findChargeType(payment.chargeType) }}</b>
                        paid by
                        <b style="text-transform: capitalize">{{
                          payment.paymentParty
                        }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-wrap">
                        {{ payment.paymentTerm }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row justify="center">
                        <v-btn icon small @click="editPaymentItem(payment)"
                          ><v-icon small>edit</v-icon></v-btn
                        >
                        <v-btn
                          icon
                          small
                          @click="deletePaymentItem(payment)"
                          color="red"
                          ><v-icon small>delete</v-icon></v-btn
                        >
                      </v-row>
                    </v-list-item-action>
                  </v-list-item> -->
                  <v-list-item
                      v-for="payment in booking.bookingPaymentDetails"
                      :key="payment.index"
                    >
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 12px">
                          {{ findChargeType(payment.chargeType) }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="payment.party"
                          class="text-wrap"
                          style="font-size: 11px"
                        >
                        <v-icon small class="mr-1">groups</v-icon> {{ payment.party.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          class="text-wrap"
                          style="font-size: 11px"
                        >
                         Term: {{ payment.paymentTerm }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-btn icon small @click="editPaymentItem(payment)"
                            ><v-icon small>edit</v-icon></v-btn
                          >
                          <v-btn
                            icon
                            small
                            @click="deletePaymentItem(payment)"
                            color="red"
                            ><v-icon small>delete</v-icon></v-btn
                          >
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="4">
            <v-card
              outlined
              style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              "
            >
              <v-card-text class="my-0 py-0">
                <v-list dense subheader>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">sell</v-icon>
                    Additional Products</v-subheader
                  >
                  <v-divider></v-divider>

                    <v-list-item v-if="productSummary().length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px;color: grey">No additional products</span>
                    </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="(service, index) in productSummary()" :key="index">
                      <v-list-item-action>
                        <v-chip>{{ service.count }}</v-chip>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ service.product }}: {{ service.variation }}
                        </v-list-item-title>
                      </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">support_agent</v-icon>
                    Additional Services</v-subheader
                  >
                  <v-divider></v-divider>

                    <v-list-item v-if="serviceSummary().length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px;color: grey">No additional services</span>
                    </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="(service, index) in serviceSummary()" :key="index">
                      <v-list-item-action>
                        <v-chip>{{ service.count }}</v-chip>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ service.service }}
                        </v-list-item-title>
                      </v-list-item-content>
                      </v-list-item>
                  </v-list>
              </v-card-text>
              </v-card>
              </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-dialog
      v-model="incoTermModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Inco Term </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="incoTermModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="code in incoTerms"
              :key="code.id"
              @click="setIncoTerm(code)"
              style="height: 45px"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ code.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ code.abbreviation }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Payment modal -->
    <v-dialog
      v-model="paymentModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Payment Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            icon
            :disabled="
              !paymentItem.chargeType ||
              !paymentItem.paymentTerm ||
              !paymentItem.partyId
            "
            :loading="savingPaymentItem"
            @click="savePaymentItem"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="(paymentModal = false), (paymentItem = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-select
            v-model="paymentItem.chargeType"
            outlined
            dense
            label="Charge Type*"
            item-text="value"
            item-value="key"
            :items="paymentTypes"
          ></v-select>
          <v-select
            v-model="paymentItem.paymentTerm"
            outlined
            dense
            label="Payment Term*"
            item-text="value"
            item-value="key"
            :items="paymentTerms"
            hide-details
          ></v-select>
          <v-list-item @click="payerModal = true">
            <v-list-item-action class="mr-2">
              <v-icon small>groups</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="paymentItem.party" style="font-size: 12px">
                {{ paymentItem.party.name }}
              </v-list-item-title>
               <v-list-item-title v-else>
               -
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 11px">
                Payment Party
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-select
            v-model="paymentItem.paymentParty"
            outlined
            dense
            label="Payer*"
            :items="availableParties"
          ></v-select> -->
          <v-autocomplete
            hide-no-data
            dense
            class="mt-2"
            outlined
            clearable
            :loading="paymentTownLoading"
            :search-input.sync="searchTowns"
            v-model="paymentItem.paymentLocation"
            :menu-props="{ closeOnContentClick: true }"
            label="Payment Location"
            :items="paymentTowns"
            item-text="name"
            item-value="locode"
            :filter="filterObject"
          >
            <template v-slot:selection="data">
              <span style="font-size: 12px"
                >{{ data.item.name }} ({{ data.item.locode }})</span
              >
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.locode }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="contractAddModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Manage Contract</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="red"
            @click="deleteContract()"
            v-if="contractItem.id"
            :loading="deletingContract"
            ><v-icon>delete</v-icon></v-btn
          >
          <v-btn
            icon
            color="secondary"
            @click="saveContract()"
            :disabled="!contractItem.contractNo || !contractItem.role"
            :loading="savingContract"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn icon @click="(contractAddModal = false), (contractItem = {})"
            ><v-icon>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-subtitle v-if="contractItem.bookingContainer">
          <v-chip v-if="contractItem.bookingContainer">
            <v-icon small left>widgets</v-icon
            >{{ contractItem.bookingContainer.ctoNo }}</v-chip
          >
        </v-card-subtitle>
        <v-card-text>
          <v-select
            outlined
            label="Contract Type"
            :items="contractTypes"
            dense
            v-model="contractItem.typeCode"
            item-text="name"
            item-value="value"
          ></v-select>
          <v-text-field
            label="Contract No.*"
            outlined
            dense
            clearable
            v-model="contractItem.contractNo"
          ></v-text-field>
          <v-select
            outlined
            label="Contract Owner*"
            :items="availableOrganisations"
            dense
            v-model="contractItem.contractOwnerId"
            item-text="name"
            item-value="id"
          ></v-select>
          <v-select
            outlined
            label="Owner Role*"
            :items="availableParties"
            dense
            v-model="contractItem.role"
          ></v-select>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Shipment profile config -->
    <v-dialog
      v-model="shipmentProfileConfig"
      width="800px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Profile Configuration </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="shipmentProfileConfig = false">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="booking.consigneeProfile">
          <v-list dense>
            <v-card
            class="my-2 py-2"
            style="background-color: var(--v-greyRaised-base) !important"

            v-for="term in booking.consigneeProfile.consigneeProfileIncoTerms" :key="term.id"
             :disabled="booking.contractPartyId && term.contractPartyId !== booking.contractPartyId"
              @click="setDealTerm(term)"
          >
            <v-card-text class="my-0 py-0">
              <v-row class="pa-1">
                <v-col cols="12" md="6">
                  <v-list dense subheader class="my-0 py-0">
                    <v-subheader style="font-size: 14px">Details</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.contractParty &&
                            term.contractParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.contractParty &&
                              term.contractParty.logo
                            "
                            :src="term.contractParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.contractParty">
                            {{ term.contractParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.contractParty"
                        >
                          <span
                            v-if="term.contractParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.contractParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.contractParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Contract Owner
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 30px">
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                        >
                          {{ term.incoTerm }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          INCO Term
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 12px"
                          class="text-wrap"
                          v-if="term.comment"
                        >
                          {{ term.comment }}
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 12px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Notes
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6">
                  <v-list dense subheader class="my-0 py-0">
                    <v-subheader style="font-size: 14px">Charges</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.originParty &&
                            term.originParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.originParty &&
                              term.originParty.logo
                            "
                            :src="term.originParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.originParty">
                            {{ term.originParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.originParty"
                        >
                          <span
                            v-if="term.originParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.originParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.originParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ term.originTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Origin Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.freightParty &&
                            term.freightParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.freightParty &&
                              term.freightParty.logo
                            "
                            :src="term.freightParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.freightParty">
                            {{ term.freightParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.freightParty"
                        >
                          <span
                            v-if="term.freightParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.freightParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.freightParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ term.paymentTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Freight Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.destinationParty &&
                            term.destinationParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.destinationParty &&
                              term.destinationParty.logo
                            "
                            :src="term.destinationParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.destinationParty">
                            {{ term.destinationParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.destinationParty"
                        >
                          <span
                            v-if="term.destinationParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.destinationParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.destinationParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ term.destinationTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Destination Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Party search modal -->
    <v-dialog
      v-model="partyModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card :loading="loadingLinkedOrganisations" v-if="partyModal">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage {{ partyType }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color='danger' text @click="removeParty"><v-icon>remove_circle_outline</v-icon></v-btn>
                </template>
                <span style="font-size: 12px"> Remove {{ partyType }} </span>
          </v-tooltip>
          
          <v-btn text @click="(partyModal = false), (searchOrganisation = null)">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            placeholder="Search"
            prepend-inner-icon="search"
            v-model="searchOrganisation"
            clearable
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="organisation in filterCustomerOrganisations"
              :key="organisation.id"
              @click="setParty(organisation.relatedOrganisation)"
            >
              <v-list-item-action>
                <v-avatar
                  v-if="organisation.relatedOrganisation.logo"
                  color="white"
                >
                  <v-img
                    :src="organisation.relatedOrganisation.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-avatar v-else-if="organisation.relatedOrganisation.name" color="secondary">
                  <h3>{{ organisation.relatedOrganisation.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="organisation.relatedOrganisation.alias"
                >
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="payerModal" width="500px" persistent>
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px"> Payer </v-toolbar-title>
          <v-spacer />
          <v-btn icon color="redPop" @click="payerModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            prepend-inner-icon="search"
            placeholder="Search"
            v-model="searchPayer"
            hide-details
            clearable
          ></v-text-field>
          <v-list dense subheader style="max-height: 400px; overflow-y: auto">
            <template v-for="(item, index) in availablePayerParties">
              <div v-if="item.type == 'SUBHEADER'" :key="index">
                <v-divider></v-divider>
                <v-subheader style="font-size: 14px">
                  {{ item.name }}
                </v-subheader>
                <v-divider></v-divider>
              </div>
              <v-list-item
                v-else
                :key="item.id"
                @click="setPaymentParty(item)"
              >
                <v-list-item-action>
                  <v-avatar
                    :color="item.logo ? 'white' : 'secondary'"
                    size="32"
                  >
                    <v-img v-if="item.logo" :src="item.logo" contain />
                    <h3 v-else>
                      {{ item.name.charAt(0) }}
                    </h3>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Customer Search Modal -->
    <v-dialog
      v-model="customerSearchModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card :loading="loadingRelatedOrganisations" v-if="customerSearchModal">
        <v-card-title>
          Search Customer
          <v-spacer></v-spacer>
          <v-btn text @click="customerSearchModal = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            autofocus
            prepend-inner-icon="search"
            v-model="searchCustomer"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
          <v-list style="max-height: 50vh; overflow-y: auto" dense>
            <v-list-item
              v-for="organisation in filterOrganisations"
              :key="organisation.id"
              :disabled="organisation.clientStatus=='ON HOLD' || organisation.clientStatus == 'INACTIVE'"
              @click="setCustomer(organisation)"
            >
              <v-list-item-action>
                <v-avatar
                  size="40"
                  v-if="organisation.relatedOrganisation.logo"
                  color="white"
                >
                  <v-img
                    :src="organisation.relatedOrganisation.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-avatar size="40" v-else-if="organisation.relatedOrganisation.name" color="secondary">
                  <h3>{{ organisation.relatedOrganisation.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="organisation.relatedOrganisation.alias"
                >
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="organisation.clientStatus"
                >
                <v-chip x-small outlined style="border:none">
                  <v-icon small left :color="getClientStatusColor(organisation.clientStatus)">fiber_manual_record</v-icon>
                  {{organisation.clientStatus}}
                </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterOrganisations.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey"
                  >No organisations found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Select shipment profile -->
    <v-dialog
      v-model="shipmentProfileDialog"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card v-if="shipmentProfileDialog">
        <v-card-title>
          Search Shipment Profiles
          <v-spacer></v-spacer>
          <v-btn text @click="shipmentProfileDialog = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="end">
            <v-col cols="12" sm="4">
              <v-text-field
                placeholder="Search"
                autofocus
                prepend-inner-icon="search"
                v-model="searchProfiles"
                outlined
                dense
                clearable
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            id="profile-info"
            style="cursor: pointer; font-size: 12px"
            :items="filteredProfiles"
            dense
            :loading="loadingShipmentProfiles"
            :headers="shipmentProfileHeaders"
            fixed-header
            :search="searchProfiles"
            height="70vh"
            @click:row="setShipmentProfile"
          >
            <template v-slot:[`item.systemReference`]="{ item }">
              <b>{{ item.systemReference }}</b>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                v-if="item.status == 'Draft'"
                color="blue"
                class="white--text"
                small
                >Draft</v-chip
              >
              <v-chip
                v-else-if="item.status == 'Approved'"
                color="success"
                class="white--text"
                small
                >Approved</v-chip
              >
            </template>
            <template v-slot:[`item.shipper.name`]="{ item }">
              <span v-if="item.shipper">{{ item.shipper.name }}</span>
              <span>
                <span v-if="item.onBehalfShipper">
                  <br />
                  On behalf of {{ item.onBehalfShipper.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.forwarder.name`]="{ item }">
              <span v-if="item.forwarder">{{ item.forwarder.name }}</span>
              <span>
                <span v-if="item.onBehalfForwarder">
                  <br />
                  On behalf of {{ item.onBehalfForwarder.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.consignee.name`]="{ item }">
              <span v-if="item.consignee">{{ item.consignee.name }}</span>
              <span>
                <span v-if="item.onBehalfConsignee">
                  <br />
                  On behalf of {{ item.onBehalfConsignee.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.buyer.name`]="{ item }">
              <span v-if="item.buyer">{{ item.buyer.name }}</span>
              <span>
                <span v-if="item.onBehalfBuyer">
                  <br />
                  On behalf of {{ item.onBehalfBuyer.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.loadingPorts`]="{ item }">
              <v-chip
                color="success"
                pill
                outlined
                small
                v-if="item.allOriginPorts"
              >
                <v-icon small left>check</v-icon> All Ports</v-chip
              >
              <div v-else>
                <span
                  v-for="(port, i) in item.consigneeProfilePorts.filter(
                    (x) => x.type == 'loading'
                  )"
                  :key="port.id"
                >
                  <span v-if="i > 0">, </span>{{ port.name }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.dischargePorts`]="{ item }">
              <v-chip
                color="success"
                pill
                outlined
                small
                v-if="item.allDestinationPorts"
              >
                <v-icon small left>check</v-icon> All Ports</v-chip
              >
              <div v-else>
                <span
                  v-for="(port, i) in item.consigneeProfilePorts.filter(
                    (x) => x.type == 'discharge'
                  )"
                  :key="port.id"
                >
                  <span v-if="i > 0">, </span>{{ port.name }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.originCountry.name`]="{ item }">
              <v-tooltip top v-if="item.originCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img
                      contain
                      v-if="item.originCountry && item.originCountry.iso2"
                      :src="`https://cdn.loglive.io/flags/4x3/${item.originCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.originCountry.name
                }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.documentationPOD`]="{ item }">
              <template v-if="item.consigneeProfilePorts.length > 0">
                {{ item.consigneeProfilePorts[0].name }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </template>

            <template v-slot:[`item.destinationCountry.name`]="{ item }">
              <v-tooltip top v-if="booking.destinationCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img
                      contain
                      v-if="
                        item.destinationCountry && item.destinationCountry.iso2
                      "
                      :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.destinationCountry.name
                }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.incoTerm`]="{ item }">
              <v-chip
                small
                outlined
                class="mt-1"
                v-for="term in item.consigneeProfileIncoTerms"
                :key="term.id"
                >{{ term.incoTerm }}</v-chip
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
<!-- 
    <v-dialog v-model="viewFormModal" width="750px">
      <FormModal
        :integrationForm="form"
        @close="viewFormModal = false"
        @saved="updateForm"
      />
    </v-dialog> -->


  </div>
</template>
<script>
// import FormModal from "./Dialogs/FormModal.vue";
export default {
  props: [
    "booking",
    "containers",
    "linkedOrganisations",
    "loadingLinkedOrganisations",
    "loadingRelatedOrganisations",
    "related",
  ],
  components: {
    // FormModal
  },
  data: () => ({
    contractItem: {},
    contractAddModal: false,
    contractKey: 150,
    contractTypes: [
      {
        name: "Coldstore",
        value: "COLDSTORE",
      },
      {
        name: "Shipping Line",
        value: "SHIPPING_LINE",
      },
      {
        name: "Weigh Bridge",
        value: "WEIGH_BRIDGE",
      },
    ],
    customerSearchModal: false,
    form: null,
    fullscreen: false,
    generatingQ67: false,
    imageLoading: false,
    incoTermModal: false,
    incoTerms: [],
    item: {},
    integrationForms: [],
    loadingForms: false,
    loadingShipmentProfiles: false,
    partyModal: false,
    partyType: null,
    partyTypes: [
      {
        name: "Bank",
        value: "bank",
        allowLink: false,
        icon: "account_balance",
        conditionalFields: ["letterOfCredit"],
      },
      {
        name: "Shipper",
        value: "shipper",
        allowLink: true,
        icon: "directions_boat_filled",
        linkedValue: "onBehalfShipper",
      },
      {
        name: "Forwarder",
        value: "forwarder",
        allowLink: true,
        icon: "fast_forward",
        linkedValue: "onBehalfForwarder",
      },
      {
        name: "Buyer",
        value: "buyer",
        allowLink: true,
        icon: "shopping_cart",
        linkedValue: "onBehalfBuyer",
      },
      {
        name: "Consignee",
        value: "consignee",
        allowLink: true,
        icon: "local_shipping",
        linkedValue: "onBehalfConsignee",
      },
      {
        name: "Courier",
        value: "courierParty",
        allowLink: false,
        icon: "local_post_office",
      },
      {
        name: "Notify",
        value: "firstNotify",
        allowLink: false,
        icon: "notifications",
      },
      {
        name: "2nd Notify Party",
        value: "secondNotify",
        allowLink: false,
        icon: "notifications",
      },
      {
        name: "Customer",
        value: "customer",
      },
      {
        name: "Contract Party",
        value: "contractParty",
      },
    ],
    paymentModal: false,
    paymentItem: {},
    paymentKey: 8000,
    paymentTypes: [
      { key: "Additional", value: "Additional Charges" },
      { key: "OceanFreight", value: "Basic Freight" },
      { key: "DestinationHaulage", value: "Destination Haulage Charges" },
      { key: "DestinationTerminalHandling", value: "Destination Port Charges" },
      { key: "OriginTerminalHandling", value: "Origin Haulage Charges" },
      { key: "OriginHaulage", value: "Origin Port Charges" },
    ],
    paymentTerms: [
      { key: "Prepaid", value: "Pre-paid" },
      { key: "Collect", value: "Collect" },
      { key: "PayableElsewhere", value: "Payable Elsewhere" },
    ],
    paymentTowns: [],
    paymentTownTimeout: undefined,
    paymentTownLoading: false,
    payerModal: false,
    searchPayer: null,
    previewModal: false,
    profileKey: 100,
    
    savingContract: false,
    searchCustomer: null,
    searchOrganisation: null,
    searchProfiles: null,
    searchTowns: null,
    shipmentProfileHeaders: [
      {
        text: "System Ref",
        value: "systemReference",
        align: "left",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },

      {
        text: "Reference",
        value: "reference",
        align: "center",
      },
      {
        text: "Consignee",
        value: "consignee.name",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipper.name",
        align: "center",
      },
      {
        text: "Forwarder",
        value: "forwarder.name",
        align: "center",
      },
      {
        text: "Origin",
        value: "originCountry.name",
        sortable: false,
        align: "center",
      },
      {
        text: "Loading Ports",
        value: "loadingPorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Destination",
        value: "destinationCountry.name",
        sortable: false,
        align: "center",
      },

      {
        text: "Discharge Ports",
        value: "dischargePorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Final Dest.",
        value: "finalDestinationCity",
        sortable: false,
        align: "center",
        documentName: null,
      },
      {
        text: "Inco Term(s)",
        value: "incoTerm",
        sortable: false,
        align: "center",
      },
    ],
    savingPaymentItem: false,
    shipmentProfileConfig: false,
    shipmentProfileDialog: false,
    shipmentProfiles: {
      data: [],
    },
    viewFormModal: false,
  }),
  watch: {
    "booking.customerId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getShipmentProfiles()
        }
      },
    },
    searchTowns(val) {
      if (this.paymentTownLoading) {
        clearTimeout(this.paymentTownTimeout);
      }
      if (val && val.length > 2) {
        this.paymentTownLoading = true;
        this.paymentTownTimeout = setTimeout(async () => {
          this.paymentTowns = await this.$API.searchTowns({
            params: {
              search: val,
            },
          });
          this.paymentTownLoading = false;
        }, 250);
      } else {
        clearTimeout(this.paymentTownTimeout);
        this.paymentTownLoading = false;
      }
    },
  },
  async created(){
    this.incoTerms = await this.$API.getIncoTerms();
  },
  computed: {
    availableOrganisations() {
      let keys = [
        "customer",
        "shipper",
        "forwarder",
        "consignee",
        "firstNotify",
        "secondNotify",
        "shippingLine",
      ];
      let result = [];
      for (let i = 0; i < keys.length; i++) {
        if (this.booking[keys[i]]) {
          result.push({
            name: this.booking[keys[i]].name,
            id: this.booking[keys[i] + "Id"],
          });
        }
      }
      return result;
    },
    availableParties() {
      let keys = [
        "Shipper",
        "Forwarder",
        "Consignee",
        "Notify Party",
        "Shipping Line",
        "Other",
      ];
      return keys;
    },
    availablePayerParties() {
      let result = [
        {
          type: "SUBHEADER",
          name: "Profile Parties",
        },
      ];
      for (let party of this.partyTypes) {
        if (this.booking[party.value]) {
          result.push({
            type: party.name,
            name: this.booking[party.value].name,
            id: this.booking[party.value].id,
            friendlyName:
              this.booking[party.value].friendlyName ??
              this.booking[party.value].alias,
            logo: this.booking[party.value].logo,
          });
        }
      }
      if (result.length == 1) {
      result = []  
      }
      result.push({
        type: "SUBHEADER",
        name: "Other Parties",
      });
      result = [
        ...result,
        ...this.related.data
          .map((x) => x.relatedOrganisation)
          .filter((x) => !result.some((y) => y.id == x.id)),
      ];
      if (this.searchPayer) {
        result = result.filter(
          (x) =>
            x.type != "SUBHEADER" &&
            x.name.toLowerCase().includes(this.searchPayer.toLowerCase())
        );
      }
      return result;
    },
    filterCustomerOrganisations() {
      let result = [...this.linkedOrganisations] ?? [];
      if (this.booking.customer) {
        let find = result.find(
          (x) => x.relatedOrganisationId == this.booking.customerId
        );
        if (!find) {
          result.push({
            relatedOrganisation: this.booking.customer,
            relatedOrganisationId: this.booking.customerId,
          });
        }
      }
      if (this.searchOrganisation) {
        result = result.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchOrganisation.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchOrganisation.toLowerCase()))
        );
      }
      result.sort((a, b) =>
        a.relatedOrganisation.name > b.relatedOrganisation.name
          ? 1
          : b.relatedOrganisation.name > a.relatedOrganisation.name
          ? -1
          : 0
      );
      return result;
    },
    filterOrganisations() {
      let result = [...this.related.data] ?? [];
      console.log(result)
      result = result.filter((x) => x.isActive && x.isCustomer);
      // let unique = [...new Set(result.map((x) => x.relatedOrganisationId))];
      // unique = unique.map((x) =>
      //   result.find((y) => y.relatedOrganisationId == x)
      // );
      // let finalResult = [];
      // for (let i = 0; i < unique.length; i++) {
      //   let find = result.find(
      //     (x) => x.relatedOrganisationId == unique[i].relatedOrganisationId
      //   );
      //   if (find) {
      //     finalResult.push(find);
      //   }
      // }
      if (this.searchCustomer) {
        result = result.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchCustomer.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchCustomer.toLowerCase()))
        );
      }

      return result;
    },
    filteredProfiles() {
      let result = this.shipmentProfiles.data.filter(
        (x) => x.status == "Approved"
      );
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.systemReference &&
              x.systemReference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.reference &&
              x.reference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.buyer &&
              x.buyer.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.forwarder &&
              x.forwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfForwarder &&
              x.onBehalfForwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfShipper &&
              x.onBehalfShipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfConsignee &&
              x.onBehalfConsignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consigneeProfileIncoTerms &&
              x.consigneeProfileIncoTerms
                .map((y) => y.incoTerm.toLowerCase())
                .includes(this.searchProfiles.toLowerCase()))
        );
      }
      return result;
    },
  },
  methods: {
    addContractItem(
      item = {
        typeCode: "SHIPPING_LINE",
        type: "Shipping Line",
        bookingId: this.booking.id,
      }
    ) {
      this.contractItem = item;
      this.contractAddModal = true;
    },
    addPayment() {
      if (!this.booking.bookingPaymentDetails) {
        this.booking.bookingPaymentDetails = [];
      }
      this.paymentItem = {
        index: this.booking.bookingPaymentDetails.length,
      };
      this.paymentModal = true;
    },
    async deleteContract() {
      this.deletingContract = true;
      await this.$API.updateContract({
        id: this.contractItem.id,
        isActive: false,
        isDeleted: true,
      });
      this.booking.linkedContracts = this.booking.linkedContracts.filter(
        (x) => x.id != this.contractItem.id
      );
      this.contractAddModal = false;
      this.deletingContract = false;
    },
    deletePaymentItem(item) {
      this.booking.bookingPaymentDetails.splice(item.index, 1);
    },
   
    editPaymentItem(item) {
      this.paymentItem = item;
      this.paymentModal = true;
    },

    findChargeType(type) {
      return this.paymentTypes.find((x) => x.key == type).value;
    },
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.locode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    formatParty(party) {
      switch (party) {
        case "forwarder":
          return "Forwarder";
        case "buyer":
          return "Buyer";
        case "consignee":
          return "Consignee";
        case "firstNotify":
          return "Notify Party";
        case "secondNotify":
          return "Notify Party";
        default:
          return party;
      }
    },
  
    async generateQ67Excel() {
      this.generatingQ67 = true;
      let result = await this.$API.generateQ67Excel({
        bookingId: this.booking.id,
        comment: this.q67Comment,
      });
      if (result) {
        this.booking.q67 = result;
        this.booking.q67Id = result.id;
        this.q67CommentModal = false;
        this.q67Comment = null;
        this.$message({
          type: "success",
          message: `Q67 Generated Successfully`,
        });
      }
      this.generatingQ67 = false;
    },
   
    async getShipmentProfiles() {
      this.loadingShipmentProfiles = true;
      this.shipmentProfiles = await this.$API.listCustomerConsigneeProfile(
        this.booking.customerId,
        {}
      );
      this.loadingShipmentProfiles = false;
    },
    getClientStatusColor(status) {
            switch (status) {
                case 'GOOD STANDING':
                    return 'green'
                case 'INACTIVE':
                    return 'red'
                case 'ON HOLD':
                    return 'orange'
                case 'COD':
                    return 'blue'
            }
        },
    productSummary(){
      let products = [].concat.apply([], this.containers.map(x=>x.temporaryProducts))
      products.forEach(x=>{
        x.hash = x.product + x.variation
      })
      let unique = [...new Set(products.map(x=>x.hash))]
      let result = []
      for(let i=0;i<unique.length;i++){
        let filtered = products.filter(x=>x.hash == unique[i])
        let detail = filtered[0]
        let obj = {
          count: filtered.length,
          product: detail.product,
          variation: detail.variation,
          monitoringService: detail.monitoringService
        }
        result.push(obj)
      }
      result.sort((a,b) => (a.product > b.product) ? 1 : ((b.product > a.product) ? -1 : 0))
      return result
    },
    serviceSummary(){
      let result = []
      for(let i=0; i<this.containers.length;i++){
        let uniqueServices = [...new Set(this.containers[i].temporaryProducts.filter(x=>x.monitoringService).map(x=>x.monitoringService))]
        for(let j=0;j<uniqueServices.length;j++){
          let find = result.find(x=>x.service == uniqueServices[j])
          if(find){
            find.count ++
          } else {
            result.push({
              count: 1,
              service: uniqueServices[j]
            })
          }
        }
      }
      // let products = [].concat.apply([], this.containers.map(x=>x.temporaryProducts))
      // products.forEach(x=>{
      //   x.hash = x.monitoringService
      // })
      // let unique = [...new Set(products.filter(x=>x.hash).map(x=>x.hash))]
      // let result = []
      // for(let i=0;i<unique.length;i++){
      //   let filtered = products.filter(x=>x.hash == unique[i])
      //   let detail = filtered[0]
      //   let obj = {
      //     count: filtered.length,
      //     service: detail.monitoringService
      //   }
      //   result.push(obj)
      // }
      return result
    },

    
    removeProfile() {
      this.booking.consigneeProfile = null;
      this.booking.consigneeProfileId = null;
      this.booking.dealTerm = null;
      this.booking.dealTermId = null;
      this.profileKey++;
      this.$emit("bookingChange");
    },
    async saveContract() {
      this.savingContract = true;
      if (this.contractItem.typeCode) {
        let find = this.contractTypes.find(
          (x) => x.value == this.contractItem.typeCode
        );
        this.contractItem.type = find.name;
        if (
          this.contractItem.typeCode == "SHIPPING_LINE" &&
          !this.contractItem.bookingContainerId
        ) {
          this.booking.contractNumber = this.contractItem.contractNo;
        }
      }
      if (this.contractItem.id) {
        await this.$API.updateContract(this.contractItem);
      } else {
        let result = await this.$API.createContract(this.contractItem);
        // if(this.contractItem.contractOwnerId){
        //     this.contractItem.contractOwner = this.availableOrganisations.find(x => x.id == this.contractItem.contractOwnerId)
        // }
        this.booking.linkedContracts.push(result);
      }
      this.contractAddModal = false;
      this.savingContract = false;
      this.$emit("bookingChange");

    },
    async savePaymentItem() {
      this.savingPaymentItem = true
      this.paymentItem.bookingId = this.booking.id
      // let find = this.booking.bookingPaymentDetails.findIndex(
      //   (x) => x.index === this.paymentItem.index
      // );
      if (this.paymentItem.id) {
        await this.$API.updateBookingPayment(this.paymentItem)
        this.booking.bookingPaymentDetails[find] = this.paymentItem;
      } else {
        let result = await this.$API.createBookingPayment(this.paymentItem)
        this.booking.bookingPaymentDetails.push(result);
      }
      this.paymentKey++;
      this.paymentModal = false;
      this.paymentItem = {};
      this.savingPaymentItem = false
    },
    async setCustomer(organisation) {
      this.booking.customer = organisation.relatedOrganisation;
      this.booking.customerId = organisation.relatedOrganisationId;
      let parties = [
        "buyer",
        "shipper",
        "consignee",
        "forwarder",
        "firstNotify",
        "secondNotify",
        "courierParty",
      ];
      for (let i = 0; i < parties.length; i++) {
        this.booking[parties[i]] = null;
        this.booking[parties[i] + "Id"] = null;
      }
      for (let i = 0; i < parties.length; i++) {
        if (organisation[parties[i]]) {
          this.booking[parties[i]] = organisation.relatedOrganisation;
          this.booking[parties[i] + "Id"] = organisation.relatedOrganisationId;
        }
      }

      this.customerSearchModal = false;
      this.$emit("bookingChange");

    },
    async setDealTerm(term) {
      this.booking.dealTermId = term.id;
      this.booking.dealTerm = term;
      this.booking.incoTerm = term.incoTerm;
      this.shipmentProfileConfig = false;
      this.booking.bookingPaymentDetails = [];
      if(!this.booking.contractPartyId){
        this.booking.contractPartyId = term.contractPartyId
        this.booking.contractParty = term.contractParty
      }
      if(this.booking[term.contractOwner]){
        this.booking.contractParty = this.booking[term.contractOwner]
        this.booking.contractPartyId = this.booking[term.contractOwner + "Id"]
      }
      await this.$API.removeExistingBookingPaymentDetails(this.booking.id)
      if (this.booking.dealTerm) {
        if (this.booking.dealTerm.freightCharge) {
          this.paymentItem = {
            chargeType: "OceanFreight",
            index: this.booking.bookingPaymentDetails.length,
            paymentTerm: this.booking.dealTerm.paymentTerm,
            paymentParty: this.formatParty(this.booking.dealTerm.freightCharge),
          };
          await this.savePaymentItem();
        }

        if (this.booking.dealTerm.originCharge) {
          this.paymentItem = {
            chargeType: "OriginTerminalHandling",
            index: this.booking.bookingPaymentDetails.length,
            paymentTerm: this.booking.dealTerm.originTerm,
            paymentParty: this.formatParty(this.booking.dealTerm.originCharge),
          };
          await this.savePaymentItem();
        }

        if (this.booking.dealTerm.destinationCharge) {
          this.paymentItem = {
            chargeType: "DestinationTerminalHandling",
            index: this.booking.bookingPaymentDetails.length,
            paymentTerm: this.booking.dealTerm.destinationTerm,
            paymentParty: this.formatParty(
              this.booking.dealTerm.destinationCharge
            ),
          };
          await this.savePaymentItem();
        }
        // if (term.consigneeProfileShippingContracts) {
        //     this.selectedCarriers = term.consigneeProfileShippingContracts.filter(x => x.shippingLine).map(x => x.shippingLine.scacCode).filter(Boolean)
        //     this.searchKey++
        // }
        // incoTerm.consigneeProfileShippingContracts
      }
      this.$emit("bookingChange");

    },
    setIncoTerm(term) {
      this.booking.incoTerm = term.abbreviation;
      this.incoTermModal = false;
      this.$emit('bookingChange')
    },
    removeImportShipperPart(){
      this.partyType = 'Shipper'
      this.booking.shipper = null
      this.booking.shipperId = null
      this.$emit("bookingChange");

    },
    setParty(organisation) {
      switch (this.partyType) {
        case "Shipper":
          this.booking.shipper = organisation;
          this.booking.shipperId = organisation.id;
          break;
        case "Consignee":
          this.booking.consignee = organisation;
          this.booking.consigneeId = organisation.id;
          break;
        case "Forwarder":
          this.booking.forwarder = organisation;
          this.booking.forwarderId = organisation.id;
          break;
        case "Buyer":
          this.booking.buyer = organisation;
          this.booking.buyerId = organisation.id;
          break;
        case "First Notify":
          this.booking.firstNotify = organisation;
          this.booking.firstNotifyId = organisation.id;
          break;
        case "Second Notify":
          this.booking.secondNotify = organisation;
          this.booking.secondNotifyId = organisation.id;
          break;
        case "Courier Party":
          this.booking.courierParty = organisation;
          this.booking.courierPartyId = organisation.id;
          break;
          case "Stock Provider":
          this.booking.stockProvider = organisation;
          this.booking.stockProviderId = organisation.id;
          break;
      }

      this.partyModal = false;
      this.$emit("bookingChange");

    },
    setPaymentParty(party){
      this.paymentItem.partyId = party.id
      this.paymentItem.party = party
      this.payerModal = false
    },
    removeParty() {
      switch (this.partyType) {
        case "Shipper":
          this.booking.shipper = null;
          this.booking.shipperId = null;
          break;
        case "Consignee":
          this.booking.consignee = null;
          this.booking.consigneeId = null;
          break;
        case "Forwarder":
          this.booking.forwarder = null;
          this.booking.forwarderId = null;
          break;
        case "Buyer":
          this.booking.buyer = null;
          this.booking.buyerId = null;
          break;
        case "First Notify":
          this.booking.firstNotify = null;
          this.booking.firstNotifyId = null;
          break;
        case "Second Notify":
          this.booking.secondNotify = null;
          this.booking.secondNotifyId = null;
          break;
        case "Courier Party":
          this.booking.courierParty = null;
          this.booking.courierPartyId = null;
          break;
          case "Stock Provider":
          this.booking.stockProvider = null;
          this.booking.stockProviderId = null;
          break;
      }

      this.partyModal = false;
      this.$emit("bookingChange");

    },
    async setShipmentProfile(profile) {
      let profileDetail = await this.$API.getConsigneeProfile(profile.id);
      this.booking.consigneeProfile = profile;
      this.booking.consigneeProfileId = profile.id;
      this.booking.ucrNo = profileDetail.ucrNo;
      let parties = [
        "shipper",
        "consignee",
        "forwarder",
        "buyer",
        "firstNotify",
        "secondNotify",
        "courierParty",
        "onBehalfShipper",
        "onBehalfConsignee",
      ];
      for (let i = 0; i < parties.length; i++) {
        this.booking[parties[i]] = profile[parties[i]];
        this.booking[parties[i] + "Id"] = profile[parties[i] + "Id"];
      }
      this.booking.originCountry = profile.originCountry;
      this.booking.originCountryId = profile.originCountryId;
      this.booking.destinationCountry = profile.destinationCountry;
      this.booking.destinationCountryId = profile.destinationCountryId;
      if (profileDetail.finalDestinationValue) {
        this.booking.finalDestinationValue =
          profileDetail.finalDestinationValue;
        this.booking.finalDestinationCity = profileDetail.finalDestinationCity;
      }
      
      this.booking.regimeCode = profileDetail.regimeCode ? profileDetail.regimeCode : this.booking.regimeCode;
      this.booking.regime = profileDetail.regime ? profileDetail.regime : this.booking.regime;
      this.booking.regimeId = profileDetail.regimeId ? profileDetail.regimeId : this.booking.regimeId;
      this.booking.caProduct = profileDetail.caProduct ? profileDetail.caProduct : this.booking.caProduct;
      // this.booking.isPrivateHaulage = profileDetail.isPrivateHaulage;
      // this.booking.isRailage = profileDetail.isRailage;
      // this.booking.isCarrierHaulage = profileDetail.isCarrierHaulage;
      if (profileDetail.consigneeProfileIncoTerms.length == 1) {
        this.setDealTerm(profileDetail.consigneeProfileIncoTerms[0]);
      }
      if (!profile.consigneeProfileProducts) {
        profile.consigneeProfileProducts =
          await this.$API.getConsigneeProfileProducts(profile.id);
      }
      if (!profile.allDestinationPorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "discharge"
        );
        if (findDest.length == 1) {
          this.booking.portOfDischargeValue = findDest[0].code;
          this.booking.finalDestinationValue = findDest[0].code;
          this.booking.portOfDischargeCity = findDest[0].name;
          this.booking.finalDestinationCity = findDest[0].name;
        }
      }

      if (!profile.allOriginPorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "origin"
        );
        if (findDest.length == 1) {
          this.booking.portOfLoadValue = findDest[0].code;
          this.booking.portOfLoadCity = findDest[0].name;
        }
      }
      this.shipmentProfileDialog = false;
      this.contractKey++;
      this.allOriginPorts = await this.$API.getPortsByCountry(
        profile.originCountry.iso2
      );
      this.allDestinationPorts = await this.$API.getPortsByCountry(
        profile.destinationCountry.iso2
      );
      this.$emit('bookingChange');

    },
    updateForm(answers) {
      this.form.answers = answers;
      this.viewFormModal = false;
    },
  
    viewForm(form) {
      this.form = form;
      this.viewFormModal = true;
    },
  },
};
</script>